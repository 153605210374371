import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { apiBaseAddress, ISurveyResponse } from '../models/survey'

const initialState: { 
  responses: { [key: string]: Array<ISurveyResponse> }, 
  status: string, 
  error: any } = {

  responses: {},
  status: 'idle',
  error: null
}

const surveysSlice = createSlice({
  name: 'surveyResponses',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(load.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(load.fulfilled, (state, action) => {
        state.status = 'succeeded'
        const index = action.meta.arg
        state.responses[index] = action.payload
      })
      .addCase(load.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      })
    //   .addCase(post.fulfilled, (state, action) => {
    //     state.status = 'succeeded'
    //     // Add new survey to the array
    //     state.surveyResponses.push(action.payload)
    //   })
    //   .addCase(get.fulfilled, (state, action) => {
    //     state.status = 'succeeded'
    //     // Add new survey to the array
    //     const survey = state.surveyResponses.findIndex(s => s.id === action.payload.id);
    //     if(survey < 0){
    //       state.surveyResponses.push(action.payload)
    //     }
    //     else {
    //       state.surveyResponses[survey] = action.payload;
    //     }
    //   })
  }
});

export const load = createAsyncThunk('results/load', async (id: string) => {
    const response = await axios.get(apiBaseAddress + `/${id}/responses`, {
      headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem("jwtToken")
      }
  })
    return response.data
})

export const post = createAsyncThunk('results/post', async (data: {postId: string, surveyResult: any, surveyResultText: string}) => {
  const response = await axios.post(apiBaseAddress + `/${data.postId}/responses`, data, {
    headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem("jwtToken")
    }
});
  return response.data
})

export const get = createAsyncThunk('results/get', async (id: string) => {
  const response = await axios.get(apiBaseAddress + `/${id}/responses`, {
      headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem("jwtToken")
      }
  })
  return response.data
})

export default surveysSlice.reducer