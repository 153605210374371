import { Image } from "@chakra-ui/react";
import { ComponentType } from "react";
import type { AccountInfo } from "@azure/msal-browser";
import { InteractionType } from "@azure/msal-browser";
import { useMsal, useMsalAuthentication } from "@azure/msal-react";
import { HuggaButton } from "hugga-reactjs-front-library";
import googleLogo from "../assets/google-logo.png";
type Props = {
  bgGradient: string;
  color: string;
  width: string;
  maxWidth: string;
  minWidth: string;
  onClick: () => void;
  children: any;
};

export default function LoginButton() {
  const buttonText = "Start with Google";

  const { instance, accounts, inProgress } = useMsal();
  const activeAccount: AccountInfo | null = instance.getActiveAccount();
  const handleLoginRedirect = () =>
    instance
      .loginRedirect({
        scopes: [],
        prompt: "select_account",
      })
      .catch((error) => console.log(error));

  return (
    <HuggaButton
      bgGradient={"linear(132.66deg, #FFFFFF -55.59%, #F9F8FA 221.41%)"}
      color="black"
      width="calc(100vw - 72px)"
      maxWidth="360px"
      minWidth="220px"
      onClick={handleLoginRedirect}
    >
      <Image src={googleLogo} alt="google" width="16px" mr={3} />
      {buttonText}
    </HuggaButton>
  );
}
