import { Serializer } from "survey-core";
import { PERSONALITY_TRAIT_TYPE } from './components/Personality/PersonalityTrait';
import { TEAMWORK_SKILL_TYPE } from './components/TeamWorkSkill/TeamWorkSkill';
import { WORKSTYLE_TYPE } from './components/Workstyle/Workstyle';

export { getSurveyType } from './utils/getSurveyType';
export { ThemesManager } from './utils/ThemesManager';
export { registerHuggaWorkstyle } from './components/Workstyle/Workstyle';
export { registerHuggaTeamWorkSkill } from './components/TeamWorkSkill/TeamWorkSkill';
export { registerHuggaPersonalityTrait } from './components/Personality/PersonalityTrait';


export function registerSurveyType() {
    Serializer.addProperty("survey", {
        name: "surveyType",
        title: "Survey Type",
        category: "general",
        choices: [{
            value: WORKSTYLE_TYPE,
            text: "Workstyle"
         },
         {
            value: TEAMWORK_SKILL_TYPE,
            text: "Teamwork Skill"
         },
         {
            value: PERSONALITY_TRAIT_TYPE,
            text: "Personality Trait"
         }],
        visibleIndex: 1,
    });
}