import React from "react";
import PropTypes from "prop-types";
import { HuggaWorkstyle } from "hugga-reactjs-front-library";
import { Person3 } from '@mui/icons-material';
import { ElementFactory, Question, Serializer, SvgRegistry } from "survey-core";
import { SurveyQuestionElementBase, ReactQuestionFactory } from "survey-react-ui";
import { PropertyGridEditorCollection } from "survey-creator-react";
import { localization } from "survey-creator-core";
import ReactDOMServer from "react-dom/server";

export const WORKSTYLE_TYPE = "workstyle";
const userPhotoExample =
  "https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlcnxlbnwwfHwwfHw%3D&w=1000&q=80";

export class HuggaWorkstyleModel extends Question {
    getType() {
      return WORKSTYLE_TYPE;
    }

    set steps(val) {
        this.setPropertyValue("steps", val);
    }

    get steps() {
        return this.getPropertyValue("steps");
    }

    set minValueLabel(val) {
        this.setPropertyValue("minValueLabel", val);
    }

    get minValueLabel() {
        return this.getPropertyValue("minValueLabel");
    }

    set maxValueLabel(val) {
        this.setPropertyValue("maxValueLabel", val);
    }

    get maxValueLabel() {
        return this.getPropertyValue("maxValueLabel");
    }
}

export function registerHuggaWorkstyle() {
    ElementFactory.Instance.registerElement(WORKSTYLE_TYPE, (name) => {
        return new HuggaWorkstyleModel(name);
    });
}

const locale = localization.getLocale("");
locale.qt[WORKSTYLE_TYPE] = "Workstyle";
locale.pe.value = "Value";

const svg = ReactDOMServer.renderToString(<Person3 />);
SvgRegistry.registerIconFromSvg(WORKSTYLE_TYPE, svg);

Serializer.addClass(
    WORKSTYLE_TYPE,
    [{
        name: "steps:number",
        default: 5,
        category: WORKSTYLE_TYPE,
    },{
        name: "minValueLabel",
        default: '',
        category: WORKSTYLE_TYPE,
    }, {
        name: "maxValueLabel",
        default: '',
        category: WORKSTYLE_TYPE,
    }],
    function () {
        return new HuggaWorkstyleModel("");
    },
    "question"
);

export class HuggaWorkstyleQuestion extends SurveyQuestionElementBase {
    constructor(props) {
        super(props);
        this.state = { value: this.question.value };
    }

    get question() {
        return this.questionBase;
    }
    
    get value() {
        return this.question.value;
    }

    set value(val) {
        this.question.value = val;
    }

    get type() {
        console.log('HuggaWorkstylePicker', this);
        return WORKSTYLE_TYPE;
    }

    handleChange = (data) => {
        this.question.value = data;
    };
      
    renderElement() {
        return (
          <div style={this.style}>
            <HuggaWorkstyle 
                steps={this.question.steps}
                firstLabel={this.question.minValueLabel}
                secondLabel={this.question.maxValueLabel}
                userAvatar={userPhotoExample} 
                onSelected={(val) => this.handleChange(val)} 
            />
          </div>
        );
    }
}

ReactQuestionFactory.Instance.registerQuestion(WORKSTYLE_TYPE, (props) => {
    return React.createElement(HuggaWorkstyleQuestion, props);
});

PropertyGridEditorCollection.register({
    fit: function (prop) {
      return prop.type === WORKSTYLE_TYPE;
    },
    getJSON: function () {
      return {
        type: WORKSTYLE_TYPE,
        steps: 5,
      };
    }
});
