import { useParams } from 'react-router'
import { useReduxDispatch, useReduxSelector } from '../redux'
import { load } from '../redux/results'
import Viewer from '../components/Viewer'
import { useEffect } from "react"
import { Link } from 'react-router-dom'
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { Container } from '@mui/material'
import { getSurveyType } from '../plugins/hugga'

const Results = () => {
    const { id } = useParams();
    const dispatch = useReduxDispatch()
    const responses = useReduxSelector(state => state.surveyResponses.responses[id!])
    const postStatus = useReduxSelector(state => state.surveyResponses.status)

    useEffect(() => {
      if (postStatus === 'idle' && id !== undefined) {
        dispatch(load(id))
      }
    }, [postStatus, dispatch])  
    
    // const columns: GridColDef[] = [
    //     { 
    //         field: 'id', 
    //         headerName: 'ID', 
    //         width: 220,
    //         cellClassName: 'sjs-underlined',
    //         renderCell: (params) => (
    //             <Link to={'/show/' + id + '/' + params.row.id}>
    //                 {params.row.id}
    //             </Link>
    //         )
    //     },

    //     { 
    //         field: 'type', 
    //         headerName: 'Type', 
    //         width: 100,
    //         valueGetter: (params: GridValueGetterParams) => {
    //             const model = params.row?.surveyModel?.json;
    //             const values = model?.calculatedValues??[]
    //             const type = values.find((v:any) =>
    //                 v.name === 'surveyType'
    //             );
    //             return `${type?.expression || 'Generic'}`
    //         }
    //     },

    //     { 
    //         field: 'audit.createdByName', 
    //         headerName: 'Full name', 
    //         flex: 1,
    //         valueGetter: (params: GridValueGetterParams) =>
    //             `${params.row.audit.createdByName || ''}`
    //     },
    //     { 
    //         field: 'audit.email',
    //         headerName: 'Email',
    //         flex: 1,
    //         valueGetter: (params: GridValueGetterParams) =>
    //             `${params.row.audit.createdBy || ''}`
    //     },
        
    //     {
    //       field: 'surveyResult',
    //       headerName: 'Results',
    //       description: 'This column has a value getter and is not sortable.',
    //       sortable: false,
    //       flex: 1,
    //       cellClassName: 'word-wrap',
    //       valueGetter: (params: GridValueGetterParams) =>
    //         JSON.stringify(params.row.surveyResult || ''),
    //     },
    //   ];
      

    // return (
    //     !responses ? <div>No results</div> :
    //     <>
    //         <h1>{'\'' + responses[0]?.surveyModel.name + '\' results'}</h1>
    //         <Container>
    //             <div style={{ height: 400, width: '100%' }}>
    //                 <DataGrid
    //                     rows={responses}
    //                     columns={columns}
    //                     pageSizeOptions={[5]}
    //                     disableRowSelectionOnClick
    //                 />
    //             </div>
    //         </Container>
    //     </>

    //     // <table className='sjs-surveys-list'>
    //     //     {responses.map(response => 
    //     //         <tr key={response.id} className='sjs-surveys-list__row'>
    //     //             <td><span>{response?.surveyModel?.json?.title ?? "New Survey" }</span></td>
    //     //             <td>
    //     //                 <Link className='sjs-button' to={'run/' + response.id}><span>Show Result</span></Link>
    //     //             </td>
    //     //         </tr>
    //     //     )}
    //     // </table>
    // )

    // const surveys = useReduxSelector(state => state.surveyResponses.responses[id!])
    // const survey = surveys?.filter(s => s.id === id)[0]
    // return (
    //     survey ?
    //         <>
    //             <h1>{'\'' + survey.surveyModel.name + '\' results'}</h1>
    //             <div className='sjs-results-container'>
    //                 <Viewer id={id as string}/>
    //             </div>
    //         </>
    //     : (<div className='sjs-results-content'>
    //             <div className='sjs-results-placeholder'>
    //                 <span>This survey doesn't have any answers yet</span>
    //             </div>
    //         </div>)
    // );
    // return <div>Results</div>

    return (

        !responses ? <div>No results</div> :
        <div style={{ margin: '16px'}}>
        <table className='sjs-surveys-list'>
            <thead>
                <tr>
                    <th>ID</th>
                    <th>Type</th>
                    <th>Full name</th>
                    <th>Email</th>
                    <th>Results</th>
                </tr>
            </thead>
            <tbody>
                {responses.map((survey:any) => 
                    <tr key={survey.id} className='sjs-surveys-list__row'>
                        <td>
                            <Link className='sjs-button' to={'/show/' + id + '/' + survey.id}>
                                <span className='survey.postId'>{survey.id}</span>
                            </Link>
                        </td>
                        <td>
                            <span>{getSurveyType(survey?.json ?? undefined)}</span>
                        </td>
                        <td>
                            <span>{survey.audit.createdByName }</span>
                        </td>
                        <td>
                            <a href={`mailto:${survey.audit.createdBy}` }>
                                {survey.audit.createdBy }</a>
                        </td>
                        <td style={{wordSpacing: 'wrap', whiteSpace: 'pre-wrap', textOverflow: 'ellipsis'}}>
                            <pre style={{fontSize: 9}}><code>
                            {  JSON.stringify(survey.surveyResult) } 
                            </code></pre>
                        </td>
                        

                        {/*<td>
                            <Link className='sjs-button' to={'run/' + survey.id}><span>Run</span></Link>
                            <Link className='sjs-button' to={'edit/' + survey.id}><span>Edit</span></Link>
                            <Link className='sjs-button' to={'results/' + survey.id}><span>Results</span></Link>
                            <span className='sjs-button sjs-remove-btn' onClick={() => dispatch(remove(survey.id))}>Remove</span>
                        </td> */}
                    </tr>
                )}
            </tbody>
        </table>
        </div>
    )
   
}

export default Results;