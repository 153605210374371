import { Model } from 'survey-core';

/**
 * Hugga survey types are a convention over configuration approach to defining
 * the type of survey. This is used to determine how to render the survey.
 * 
 * The survey type is determined by the survey's metadata. The metadata is
 * located in the survey's JSON under the `calculatedValues` array property.
 * It is expected that an object with a name property equals to `surveyType` and
 * expression property equals to the `value` will be present in the array.
 * 
 * i.e. [{ name: 'surveyType', expression: 'value' }, ...]
 * 
 * @TODO: This is a temporary solution. We should consider a more robust
 * solution in the future. Maybe a custom property in the survey JSON?
 */


export type SurveyType = 'Generic' | 'WorkStyle';

export function getSurveyType(model?: Model): SurveyType {
    
    const values = model?.calculatedValues ?? [];
    const type = values.find((v: any) => v.name === 'surveyType');

    const validTypes: SurveyType[] = ['Generic', 'WorkStyle'];
    return validTypes.includes(type?.expression as SurveyType) ? 
        type!.expression as SurveyType : 'Generic';
}