import React from "react";
import {
  Box,
  Flex,
  VStack,
  Heading,
  LinkBox,
  LinkOverlay,
  List,
  ListItem,
} from "@chakra-ui/react";
import { Link, NavLink } from "react-router-dom";

const NavItem = ({ to, label }: { to: string; label: string }) => (
  <LinkBox as="li" py={2}>
    <LinkOverlay as={NavLink} to={to}>
      {label}
    </LinkOverlay>
  </LinkBox>
);

const DataExplorerLayout = ({ children }: any) => {
  return (
    <Flex margin="0 16px">
      <Box width="200px" p="4" bgColor="white">
        <Heading size="md" mb="6"></Heading>
        <List spacing={2}>
          <ListItem>
            <NavLink to="/data-explorer" className="sjs-nav-button-rbac">
              Graph Browser
            </NavLink>
          </ListItem>
          {/* <ListItem>
            <NavLink to="/roles" className="sjs-nav-button-rbac">
              Audit Explorer
            </NavLink>
          </ListItem> */}
        </List>
      </Box>
      <Box flex="1" p="4">
        {children}
      </Box>
    </Flex>
  );
};

export default DataExplorerLayout;
