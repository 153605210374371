import { get, post, put, del } from "./common";
import { QueryRequest, QueryResponse, EdgeModel } from "./dataTypes";

/**
 * DataApi is a class that encapsulates the API calls to the backend for the
 * user management. It is used by the Redux actions to fetch and update data.
 */
export class DataApi {
  /**
   * The base address of the API.
   * @private
   * @type {string}
   * @memberof DataApi
   * @example
   * ```typescript
   * const api = new DataApi("http://localhost:3000/api");
   * ```
   */
  apiBaseAddress: string;

  constructor(apiBaseAddress: string) {
    this.apiBaseAddress = apiBaseAddress;
  }

  /**
   * Helper function to construct the full URL for an endpoint.
   * @private
   * @param {string} path The path of the endpoint.
   * @returns {string} The full URL of the endpoint.
   * @memberof DataApi
   */
  private endpoint(path: string): string {
    return `${this.apiBaseAddress}${path}`;
  }

  private get endpointRunGraphQuery(): string {
    return this.endpoint("/data-explorer/graph");
  }

  private endpointGetAssocs(vertexId: string): string {
    return this.endpoint(`/data-explorer/graph/${vertexId}/vertexs`);
  }

  runGraphQuery(token: string, request: QueryRequest): Promise<QueryResponse> {
    return post(this.endpointRunGraphQuery, token, request);
  }

  getAssocs(token: string, vertexId: string): Promise<EdgeModel[]> {
    return get(this.endpointGetAssocs(vertexId), token);
  }
}
