import React from "react";
import PropTypes from "prop-types";
import { Diversity3 } from '@mui/icons-material';
import { ElementFactory, Question, Serializer, SvgRegistry } from "survey-core";
import { SurveyQuestionElementBase, ReactQuestionFactory } from "survey-react-ui";
import { PropertyGridEditorCollection } from "survey-creator-react";
import { localization } from "survey-creator-core";
import ReactDOMServer from "react-dom/server";
import { HuggaTeamwork, HuggaTeamworkOption, } from "hugga-reactjs-front-library";

export const TEAMWORK_SKILL_TYPE = "teamwork-skill";

export class HuggaTeamWorkSkillModel extends Question { 
    getType() {
        return TEAMWORK_SKILL_TYPE;
    }

    set dimension(val) {
        this.setPropertyValue("dimension", val);
    }

    get dimension() {
        return this.getPropertyValue("dimension");
    }
}

export function registerHuggaTeamWorkSkill() {
    ElementFactory.Instance.registerElement(TEAMWORK_SKILL_TYPE, (name) => {
        return new HuggaTeamWorkSkillModel(name);
    });
}

const locale = localization.getLocale("");
locale.qt[TEAMWORK_SKILL_TYPE] = "Teamwork Skill";
locale.pe.value = "Value";

const svg = ReactDOMServer.renderToString(<Diversity3 />);
SvgRegistry.registerIconFromSvg(TEAMWORK_SKILL_TYPE, svg);

Serializer.addClass(
    TEAMWORK_SKILL_TYPE,
    [{
        name: "dimension",
        default: "Trust",
        choices: ["Trust", "Constructive Conflict", "Commitment", "Accountability", "Focus on Results"],
        category: TEAMWORK_SKILL_TYPE,
        title: "Dimension",
        isRequired: true,
    }],
    function () {
        return new HuggaTeamWorkSkillModel("");
    },
    "question"
);

export class HuggaTeamWorkSkill extends SurveyQuestionElementBase {
    
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            value: this.question.value,
        };
    }

    get question() {
        return this.questionBase;
    }
    
    get value() {
        return this.question.value;
    }

    set value(val) {
        this.question.value = val;
    }

    get type() {
        return TEAMWORK_SKILL_TYPE;
    }
    
    handleChange = (value) => {
        this.question.value = value;
        this.setState({ value });
    }

    renderElement() {
        return (
            <HuggaTeamwork onSelected={(val) => this.handleChange(val)}>
                <HuggaTeamworkOption label="Completly agree" />
                <HuggaTeamworkOption label="Agree" />
                <HuggaTeamworkOption label="Somethimes agree" />
                <HuggaTeamworkOption label="Disagree" />
                <HuggaTeamworkOption label="Completly disagree" />
            </HuggaTeamwork>          
        );
    }    
}

ReactQuestionFactory.Instance.registerQuestion(TEAMWORK_SKILL_TYPE, (props) => {
    return React.createElement(HuggaTeamWorkSkill, props);
});

PropertyGridEditorCollection.register({
    fit: function (prop) {
      return prop.type === TEAMWORK_SKILL_TYPE;
    },
    getJSON: function () {
      return {
        type: TEAMWORK_SKILL_TYPE,
        dimension: "Trust",
      };
    }
});
