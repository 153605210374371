import { Model } from "survey-core";
import { SurveyType } from "./getSurveyType";

export class ThemesManager {


    static applyTheme(theme: SurveyType, model?: Model) {
        if (!model) {
            return;
        }
        switch (theme) {
            case 'WorkStyle':
                ThemesManager.applyWorkStyleTheme(model);
                break;
            default:
                ThemesManager.applyGenericTheme(model);
        }
    }

    static applyGenericTheme(model: Model) {

    }

    static applyWorkStyleTheme(model: Model) {
        model.css = {
            page: {
                // title: 'hugga-page-title__work-style',
                
            },
            body: 'hugga__work-style sd-body'
        };
    }

}