import {
  Card,
  CardHeader,
  Heading,
  Table,
  TableCaption,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import type { QueryNodeModel } from "../redux/dataTypes";

export default function GraphNodeProps({ node }: { node: QueryNodeModel }) {
  return (
    <Card
      style={{
        borderWidth: "1px",
        borderRadius: "12px",
        borderColor: "#E2E8F0",
      }}
    >
      <CardHeader>
        <Heading size="sm">Node Properties</Heading>
      </CardHeader>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th position="sticky" top="0" bg="white">
              Property
            </Th>
            <Th position="sticky" top="0" bg="white">
              Value Id
            </Th>
            <Th position="sticky" top="0" bg="white">
              Value
            </Th>
          </Tr>
        </Thead>

        <Tbody>
          {Object.entries(node.properties).map(([key, value]) =>
            value.map((v) => (
              <Tr key={v.id}>
                <Td>{key}</Td>
                <Td>{v.id}</Td>
                <Td>{v.value}</Td>
              </Tr>
            ))
          )}
        </Tbody>
      </Table>
    </Card>
  );
}
