import { combineReducers } from "@reduxjs/toolkit";
// import { connectRouter } from "connected-react-router";
import { History } from "history";
import surveysReducer, { ReduxSurveyState } from "./surveys";
import surveyResponsesReducer from "./results";
import authReducer, { ReduxAuthState } from "./auth";
import usersReducer from "./users";
import dataReducer from "./data";
import { ReduxUserState } from "./userTypes";
import { ReduxDataState } from "./dataTypes";

export type ReduxState = {
  auth: ReduxAuthState;
  users: ReduxUserState;
  surveys: ReduxSurveyState;
  surveyResponses: any;
  router: any;
  graph: ReduxDataState;
};

const rootReducer = (history: History) =>
  combineReducers({
    auth: authReducer,
    users: usersReducer,
    surveys: surveysReducer,
    surveyResponses: surveyResponsesReducer,
    graph: dataReducer,
    // router: connectRouter(history),
  });

export default rootReducer;
