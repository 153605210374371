import React, { useEffect } from "react"
import { useParams } from 'react-router'
import { useReduxDispatch, useReduxSelector } from '../redux'
import { post } from '../redux/results'
import { Model, StylesManager } from 'survey-core'
import { Survey } from 'survey-react-ui'
import '../plugins/hugga/css/hugga.css'
import { get } from '../redux/surveys'
import { getSurveyType, ThemesManager } from '../plugins/hugga';

const Run = () => {
    const { id } = useParams();
    const dispatch = useReduxDispatch()

    useEffect(() => {
        if(id !== undefined){
            dispatch(get(id));
        }
    }, [])

    const surveys = useReduxSelector(state => state.surveys.surveys)
    const survey = surveys?.filter(s => s.id === id)[0]
    const model = survey ? new Model(survey.json): undefined;

    model
        ?.onComplete
        .add((sender: Model) => {
            dispatch(post({postId: id as string, surveyResult: sender.data, surveyResultText: JSON.stringify(sender.data)}))
        });  

    const surveyType = getSurveyType(model);
    ThemesManager.applyTheme(surveyType, model);

    return (
        model ? <Survey model={model}/> : <></>
    );
}

export default Run;