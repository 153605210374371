import { get, post, put, del } from "./common";
import {
  Capacity,
  Role,
  RoleCapacities,
  User,
  UserRoles,
  WaitingListUser,
} from "./userTypes";

/**
 * UserApi is a class that encapsulates the API calls to the backend for the
 * user management. It is used by the Redux actions to fetch and update data.
 */
export class UserApi {
  /**
   * The base address of the API.
   * @private
   * @type {string}
   * @memberof UserApi
   * @example
   * ```typescript
   * const api = new UserApi("http://localhost:3000/api");
   * ```
   */
  apiBaseAddress: string;

  constructor(apiBaseAddress: string) {
    this.apiBaseAddress = apiBaseAddress;
  }

  /**
   * Helper function to construct the full URL for an endpoint.
   * @private
   * @param {string} path The path of the endpoint.
   * @returns {string} The full URL of the endpoint.
   * @memberof UserApi
   */
  private endpoint(path: string): string {
    return `${this.apiBaseAddress}${path}`;
  }

  private get endpointCapacities(): string {
    return this.endpoint("/capacities");
  }

  private endpointRoleCapacities(roleId: number): string {
    return this.endpoint(`/roles/${roleId}/capacities`);
  }

  private endpointAssocRoles(userId: number): string {
    return this.endpoint(`/user/${userId}/roles`);
  }

  private endpointUsers(): string {
    return this.endpoint(`/user?page=1&per_page=1000`);
  }

  private endpointUserRoles(userId: number): string {
    return this.endpoint(`/user/${userId}/roles`);
  }

  private endpointRoles(): string {
    return this.endpoint(`/roles`);
  }

  private endpointRole(id: number): string {
    return this.endpoint(`/roles/${id}`);
  }

  private endpointWaitingListUsers(): string {
    return this.endpoint(`/waiting-list`);
  }

  private endpointWaitingListUsersInvite(email: string): string {
    return this.endpoint(`/waiting-list/invite/${email}`);
  }

  /**
   * Fetches all capacities from the backend.
   * @param {string} token The JWT token to authenticate the request.
   * @returns {Promise<Capacity[]>} A promise that resolves to an array of
   * capacities.
   * @memberof UserApi
   * @example
   * ```typescript
   * const api = new UserApi("http://localhost:3000/api");
   * const capacities = await api.getCapacities("token");
   * ```
   */
  getCapacities(token: string): Promise<Capacity[]> {
    return get(this.endpointCapacities, token);
  }

  /**
   * Fetches a post request to create a new capacity.
   * @param {string} token The JWT token to authenticate the request.
   * @param {number} id The ID of the capacity to fetch.
   * @returns {Promise<Capacity>} A promise that resolves to the capacity.
   * @memberof UserApi
   * @example
   * ```typescript
   * const api = new UserApi("http://localhost:3000/api");
   * const capacity = await api.createCapacity("token",
   *    "module.submodule.action");
   * ```
   */
  createCapacity(token: string, capacity: string): Promise<Capacity> {
    return post(this.endpointCapacities, token, {
      name: capacity,
    });
  }

  /**
   * Fetches a put request to associate capacities to a role.
   * @param {string} token The JWT token to authenticate the request.
   * @param {RoleCapacities} roleCapacities The role capacities to associate.
   * @returns {Promise<Role>} A promise that resolves to the role.
   * @memberof UserApi
   * @example
   * ```typescript
   * const api = new UserApi("http://localhost:3000/api");
   * const role = await api.assocCapacities("token", {
   *   id: 1,
   *  capacities: ["module.submodule.action"],
   * });
   * ```
   */
  assocCapacities(
    token: string,
    roleCapacities: RoleCapacities
  ): Promise<Role> {
    return put(
      this.endpointRoleCapacities(roleCapacities.id),
      token,
      roleCapacities.capacities
    );
  }

  /**
   * Fetches a get request to fetch all roles.
   * @param {string} token The JWT token to authenticate the request.
   * @returns {Promise<Role[]>} A promise that resolves to an array of roles.
   * @memberof UserApi
   * @example
   * ```typescript
   * const api = new UserApi("http://localhost:3000/api");
   * const roles = await api.getRoles("token");
   * ```
   */
  getRoles(token: string): Promise<Role[]> {
    return get(this.endpointRoles(), token);
  }

  /**
   * Fetches a get request to fetch a role.
   * @param {string} token The JWT token to authenticate the request.
   * @param {number} id The ID of the role to fetch.
   * @returns {Promise<Role>} A promise that resolves to the role.
   * @memberof UserApi
   * @example
   * ```typescript
   * const api = new UserApi("http://localhost:3000/api");
   * const role = await api.getRole("token", 1);
   * ```
   */
  getRole(token: string, id: number): Promise<Role> {
    return get(this.endpointRole(id), token);
  }

  /**
   * Fetches a get request to fetch the capacities of a role.
   * @param {string} token The JWT token to authenticate the request.
   * @param {number} id The ID of the role to fetch the capacities of.
   * @returns {Promise<Role>} A promise that resolves to the role.
   * @memberof UserApi
   * @example
   * ```typescript
   * const api = new UserApi("http://localhost:3000/api");
   * const role = await api.getRoleCapacities("token", 1);
   * ```
   */
  getRoleCapacities(token: string, id: number): Promise<Role> {
    return get(this.endpointRole(id), token);
  }

  /**
   * Fetches a post request to create a new role.
   * @param {string} token The JWT token to authenticate the request.
   * @param {Role} role The role to create.
   * @returns {Promise<Role>} A promise that resolves to the role.
   * @memberof UserApi
   * @example
   * ```typescript
   * const api = new UserApi("http://localhost:3000/api");
   * const role = await api.createRole("token", {
   *  name: "role",
   *  description: "description",
   * });
   * ```
   */
  createRole(token: string, role: Role): Promise<Role> {
    return post(this.endpointRoles(), token, role);
  }

  /**
   * Fetches a put request to update a role.
   * @param {string} token The JWT token to authenticate the request.
   * @param {Role} role The role to update.
   * @returns {Promise<Role>} A promise that resolves to the role.
   * @memberof UserApi
   * @example
   * ```typescript
   * const api = new UserApi("http://localhost:3000/api");
   * const role = await api.updateRole("token", {
   *  id: 1,
   *  name: "role",
   *  description: "description",
   * });
   * ```
   */
  updateRole(token: string, role: Role): Promise<Role> {
    return put(this.endpointRole(role.id), token, role);
  }

  /**
   * Fetches a delete request to delete a role.
   * @param {string} token The JWT token to authenticate the request.
   * @param {number} id The ID of the role to delete.
   * @returns {Promise<void>} A promise that resolves to void.
   * @memberof UserApi
   * @example
   * ```typescript
   * const api = new UserApi("http://localhost:3000/api");
   * await api.deleteRole("token", 1);
   * ```
   */
  deleteRole(token: string, id: number): Promise<Role> {
    return del(this.endpointRole(id), token);
  }

  /**
   * Fetches a get request to fetch the roles of a user.
   * @param {string} token The JWT token to authenticate the request.
   * @param {number} userId The ID of the user to fetch the roles of.
   * @returns {Promise<UserRoles>} A promise that resolves to the user roles.
   * @memberof UserApi
   * @example
   * ```typescript
   * const api = new UserApi("http://localhost:3000/api");
   * const userRoles = await api.getRolesByUser("token", 1);
   * ```
   */
  getRolesByUser(token: string, userId: number): Promise<UserRoles> {
    return get(this.endpointUserRoles(userId), token);
  }

  /**
   * Fetches a put request to associate roles to a user.
   * @param {string} token The JWT token to authenticate the request.
   * @param {UserRoles} userRoles The user roles to associate.
   * @returns {Promise<UserRoles>} A promise that resolves to the user roles.
   * @memberof UserApi
   * @example
   * ```typescript
   * const api = new UserApi("http://localhost:3000/api");
   * await api.assocRoles("token", {
   *  userId: 1,
   *  roles: [1, 2],
   * });
   * ```
   */
  assocRoles(token: string, userRoles: UserRoles): Promise<UserRoles> {
    return put(
      this.endpointAssocRoles(userRoles.userId),
      token,
      userRoles.roles
    );
  }

  /**
   * Fetches a get request to fetch all users.
   * @param {string} token The JWT token to authenticate the request.
   * @returns {Promise<User[]>} A promise that resolves to an array of users.
   * @memberof UserApi
   * @example
   * ```typescript
   * const api = new UserApi("http://localhost:3000/api");
   * const users = await api.getUsers("token");
   * ```
   */
  getUsers(token: string): Promise<User[]> {
    return get(this.endpointUsers(), token);
  }

  getWaitingListUsers(token: string): Promise<WaitingListUser[]> {
    return get(this.endpointWaitingListUsers(), token);
  }

  addWaitingListUser(
    token: string,
    user: WaitingListUser
  ): Promise<WaitingListUser> {
    return post(this.endpointWaitingListUsers(), token, user);
  }

  inviteWaitingListUser(
    token: string,
    user: WaitingListUser
  ): Promise<WaitingListUser> {
    return get(this.endpointWaitingListUsersInvite(user.email), token);
  }
}
