import { createSlice } from "@reduxjs/toolkit";
import { newAsyncThunk } from "./common";
import { UserApi } from "./userApi";
import {
  Capacity,
  ReduxUserState,
  Role,
  RoleCapacities,
  User,
  UserRoles,
  WaitingListUser,
} from "./userTypes";

const apiBaseAddress =
  process.env.REACT_APP_API_USER ||
  "https://t-hugga-ms-user.azurewebsites.net/api/v1";

// const apiBaseAddress =
//   process.env.REACT_APP_API_USER || "http://localhost:8080/api/v1";

const initialState: ReduxUserState = {
  capacities: [],
  roles: [],
  users: [],
  userRoles: {},
  status: "idle",
  error: null,
  waitingListUsers: [],
  statusMeta: null,
  statusType: "",
};

const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(loadCapacities.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(loadCapacities.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.capacities = action.payload.map((c: { name: string }) => c.name);
        // Add any fetched surveys to the array
      })
      .addCase(loadCapacities.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(createCapacity.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.capacities = [...state.capacities, action.payload.name];
      })
      .addCase(createCapacity.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(createCapacity.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(loadRoles.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(loadRoles.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.roles = action.payload;
      })
      .addCase(loadRoles.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(loadRoleCapacities.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(loadRoleCapacities.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.roles = state.roles.map((r) =>
          r.id === action.payload.id ? action.payload : r
        );
      })
      .addCase(loadRoleCapacities.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(updateRole.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(updateRole.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.roles = state.roles.map((r) =>
          r.id === action.payload.id ? action.payload : r
        );
      })
      .addCase(updateRole.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(createRole.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(createRole.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.roles = [...state.roles, action.payload];
      })
      .addCase(createRole.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(assocCapacities.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(assocCapacities.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.roles = state.roles.map((r) =>
          r.id === action.payload.id ? action.payload : r
        );
      })
      .addCase(assocCapacities.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(loadUsers.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(loadUsers.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.users = action.payload;
      })
      .addCase(loadUsers.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(loadUserRoles.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(loadUserRoles.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.userRoles[action.payload.userId] = action.payload.roles;
      })
      .addCase(loadUserRoles.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(assocRoles.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(assocRoles.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.userRoles[action.payload.userId] = action.payload.roles;
      })
      .addCase(assocRoles.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(deleteRole.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(deleteRole.fulfilled, (state, action) => {
        state.status = "succeeded_deleted";
        state.roles = state.roles.filter((r) => r.id !== action.payload.id);
      })
      .addCase(deleteRole.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(loadWaitingListUsers.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(loadWaitingListUsers.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.waitingListUsers = action.payload;
      })
      .addCase(loadWaitingListUsers.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(addWaitingListUser.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(addWaitingListUser.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.waitingListUsers = [...state.waitingListUsers, action.payload];
      })
      .addCase(addWaitingListUser.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(inviteWaitingListUser.pending, (state, action) => {
        state.status = "loading";
        state.statusMeta = action.meta;
        state.statusType = action.type;
      })
      .addCase(inviteWaitingListUser.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.waitingListUsers = state.waitingListUsers.map((u) =>
          u.email === action.payload.email ? action.payload : u
        );
        state.statusMeta = action.meta;
        state.statusType = action.type;
      })
      .addCase(inviteWaitingListUser.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
        state.statusMeta = action.meta;
        state.statusType = action.type;
      });
  },
});

export const userApi = new UserApi(apiBaseAddress);

export const loadCapacities = newAsyncThunk(
  "capacities/load",
  (token: string) => userApi.getCapacities(token)
);

export const createCapacity = newAsyncThunk(
  "capacities/create",
  (token: string, capacity: string) => userApi.createCapacity(token, capacity)
);

export const loadRoles = newAsyncThunk("roles/load", (token: string) =>
  userApi.getRoles(token)
);

export const loadRole = newAsyncThunk(
  "roles/load",
  (token: string, id: number) => userApi.getRole(token, id)
);

/**
 * Dispatches an action to load the capacities associated. This is used to
 * populate the capacities associated with a role.
 *
 * @param id The ID of the role to retrieve the capacities for.
 * @returns A promise that resolves to the capacities associated with the role.
 * @see {@link UserApi.loadRole}
 * @example
 * ```typescript
 * const dispatch = useDispatch();
 * const roleID = 1;
 * dispatch(loadRole(roleID));
 * ```
 */
export const loadRoleCapacities = newAsyncThunk(
  "roles/load-capacities",
  (token: string, id: number) => userApi.getRoleCapacities(token, id)
);

/**
 * Dispatches an action to create a new role.
 * @param role The role to create.
 * @returns A promise that resolves to the created role.
 * @example
 * ```typescript
 * const dispatch = useDispatch();
 * const role = { name: "New Role", description: "A new role." };
 * dispatch(createRole(role));
 * ```
 * @see {@link UserApi.createRole}
 */
export const createRole = newAsyncThunk(
  "roles/create",
  (token: string, role: Role) => userApi.createRole(token, role)
);

/**
 * Dispatches an action to update a role.
 * @param role The role to update.
 * @returns A promise that resolves to the updated role.
 * @example
 * ```typescript
 * const dispatch = useDispatch();
 * const role = { id: 1, name: "Updated Role", description: "An updated role." };
 * dispatch(updateRole(role));
 * ```
 * @see {@link UserApi.updateRole}
 */
export const updateRole = newAsyncThunk(
  "roles/update",
  (token: string, role: Role) => userApi.updateRole(token, role)
);

export const assocCapacities = newAsyncThunk(
  "roles/assoc-capacities",
  (token: string, roleCapacities: RoleCapacities) =>
    userApi.assocCapacities(token, roleCapacities)
);

/**
 * Dispatches an action to delete a role.
 * @param id The ID of the role to delete.
 * @returns A promise that resolves to the deleted role.
 * @example
 * ```typescript
 * const dispatch = useDispatch();
 * const roleID = 1;
 * dispatch(deleteRole(roleID));
 * ```
 * @see {@link UserApi.deleteRole}
 */
export const deleteRole = newAsyncThunk(
  "roles/delete",
  (token: string, id: number) => userApi.deleteRole(token, id)
);

/**
 * Dispatches an action to load the users.
 * @returns A promise that resolves to the users.
 * @example
 * ```typescript
 * const dispatch = useDispatch();
 * dispatch(loadUsers());
 * ```
 * @see {@link UserApi.getUsers}
 */
export const loadUsers = newAsyncThunk("users/load", (token: string) =>
  userApi.getUsers(token)
);

export const loadUserRoles = newAsyncThunk(
  "user-roles/load",
  (token: string, userId: number) => userApi.getRolesByUser(token, userId)
);

export const assocRoles = newAsyncThunk(
  "user-roles/assoc",
  (token: string, userRoles: UserRoles) => userApi.assocRoles(token, userRoles)
);

export const loadWaitingListUsers = newAsyncThunk(
  "waiting-list-users/load",
  (token: string) => userApi.getWaitingListUsers(token)
);

export const addWaitingListUser = newAsyncThunk(
  "waiting-list-users/add",
  (token: string, user: WaitingListUser) =>
    userApi.addWaitingListUser(token, user)
);

export const inviteWaitingListUser = newAsyncThunk(
  "waiting-list-users/invite",
  (token: string, user: WaitingListUser) =>
    userApi.inviteWaitingListUser(token, user)
);

// export const { add, remove, update } = surveysSlice.actions
export default usersSlice.reducer;
