import React, { useEffect, useState } from "react";
import {
  Route,
  NavLink,
  Routes,
  Navigate,
  redirect,
  useNavigate,
} from "react-router-dom";
import Home from "../pages/Home";
import About from "../pages/About";
import Run from "../pages/Run";
import Edit from "../pages/Edit";
import Results from "../pages/Results";
import Login from "../pages/Login";
import Capacities from "../pages/Capacities";
import Roles from "../pages/Roles";
import Users from "../pages/Users";
import Groups from "../pages/Groups";
import WaitingList from "../pages/WaitingList";

import { useMsal } from "@azure/msal-react";
import { useDispatch, useSelector } from "react-redux";
import { ReduxState } from "../redux/root-reducer";
import { cleanError } from "../redux/auth";
import { NavBar } from "../components/NavBar";
import RBACLayout from "../components/RBACLayout";
import { useToast } from "@chakra-ui/react";
import SecuredPage from "./SecuredPage";
import DataExplorerLayout from "../components/DataExplorerLayout";
import GraphExplorer from "../pages/GraphExplorer";

function NoMatch() {
  return (
    <>
      <h1>404</h1>
    </>
  );
}

function Content(): React.ReactElement {
  return (
    <Routes>
      <Route path="/login" element={<Login />}></Route>

      <Route
        path="/"
        element={
          <ProtectedRoute>
            <Home />
          </ProtectedRoute>
        }
      />
      <Route
        path="/profile"
        element={
          <ProtectedRoute>
            <Home />
          </ProtectedRoute>
        }
      />
      <Route
        path="/home"
        element={
          <ProtectedRoute>
            <Home />
          </ProtectedRoute>
        }
      />
      <Route
        path="/capacities"
        element={
          <ProtectedRoute>
            <RBACLayout>
              <Capacities />
            </RBACLayout>
          </ProtectedRoute>
        }
      />
      <Route
        path="/roles"
        element={
          <ProtectedRoute>
            <RBACLayout>
              <Roles />
            </RBACLayout>
          </ProtectedRoute>
        }
      />
      <Route
        path="/users"
        element={
          <ProtectedRoute>
            <RBACLayout>
              <Users />
            </RBACLayout>
          </ProtectedRoute>
        }
      />
      <Route
        path="/groups"
        element={
          <ProtectedRoute>
            <RBACLayout>
              <Groups />
            </RBACLayout>
          </ProtectedRoute>
        }
      />
      <Route
        path="/waiting-list"
        element={
          <ProtectedRoute>
            <WaitingList />
          </ProtectedRoute>
        }
      />
      <Route
        path="/about"
        element={
          <ProtectedRoute>
            <About />
          </ProtectedRoute>
        }
      />
      <Route
        path="/run/:id"
        element={
          <ProtectedRoute>
            <Run />
          </ProtectedRoute>
        }
      />
      <Route
        path="/edit/:id"
        element={
          <ProtectedRoute>
            <Edit />
          </ProtectedRoute>
        }
      />
      <Route
        path="/results/:id"
        element={
          <ProtectedRoute>
            <Results />
          </ProtectedRoute>
        }
      />
      <Route
        path="/data-explorer"
        element={
          <ProtectedRoute>
            <DataExplorerLayout>
              <GraphExplorer />
            </DataExplorerLayout>
          </ProtectedRoute>
        }
      />
      <Route
        path="/data-explorer/:id"
        element={
          <ProtectedRoute>
            <DataExplorerLayout>
              <GraphExplorer />
            </DataExplorerLayout>
          </ProtectedRoute>
        }
      />
      <Route element={<NoMatch />} />
    </Routes>
  );
}

const ProtectedRoute = (props: any) => {
  const { instance, accounts, inProgress } = useMsal();
  const error = useSelector((state: ReduxState) => state.auth.error);
  const dispatch = useDispatch();
  const isAuthenticated = () => {
    return instance.getActiveAccount() != null;
  };
  const toast = useToast();

  useEffect(() => {
    if (error) {
      toast({
        title: "Error",
        description: JSON.stringify(error),
        status: "error",
        duration: 9000,
        isClosable: true,
        onCloseComplete() {
          dispatch(cleanError());
        },
      });
    }
  }, [error]);

  return (
    <SecuredPage>
      <div className="sjs-client-app">
        <header className="sjs-client-app__header">
          <NavBar />
        </header>
        <main className="sjs-client-app__content">{props.children}</main>

        <footer className="sjs-client-app__footer"></footer>
      </div>
    </SecuredPage>
  );
};

export default Content;
