import React, { useEffect, useMemo } from "react"
import { useReduxDispatch } from '../redux'
import { get, update } from '../redux/surveys'
import { SurveyCreator, SurveyCreatorComponent } from 'survey-creator-react'
import '../plugins/hugga/css/hugga-survey-creator-core.css'
import { registerColorPicker } from './ColorPicker'
import { Serializer } from 'survey-core'
// import { registerHuggaWorkstyle } from '../plugins/hugga'
import { registerHuggaWorkstyle, registerHuggaTeamWorkSkill, registerSurveyType } from '../plugins/hugga'
import { registerHuggaPersonalityTrait } from "../plugins/hugga/components/Personality/PersonalityTrait"

registerSurveyType();
registerHuggaWorkstyle();
registerHuggaTeamWorkSkill();
registerHuggaPersonalityTrait();

const Editor = (params: { id: string }): React.ReactElement => {
    const dispatch = useReduxDispatch()
    const creator = useMemo(() => {
        const options = {
            showLogicTab: true,
            showTranslationTab: true
        };
        return new SurveyCreator(options);
    }, []);
    creator.isAutoSave = true;
    creator.saveSurveyFunc = (saveNo: number, callback: (no: number, success: boolean) => void) => {
        dispatch(update({ id: params.id, json: creator.getSurveyJSON(), text: creator.text }))
        callback(saveNo, true);
    }

    useEffect(() => {
        (async () => {
            const surveyAction = await dispatch(get(params.id))
            if(typeof surveyAction.payload.json === 'object') {
                creator.JSON = surveyAction.payload.json
            } else {
                creator.text = surveyAction.payload.json
            }
        })()
    }, [dispatch, creator, params.id])

    return (<>
            <SurveyCreatorComponent creator={creator}/>
        </>)
}

export default Editor