import React, { useEffect, useState } from "react";
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  Button,
  Input,
  Select,
  Stack,
  Badge,
  Text,
  FormControl,
  FormLabel,
  SimpleGrid,
  Tag,
  Center,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { ReduxState } from "../redux/root-reducer";
import { KeyboardReturn, Send } from "@mui/icons-material";
import { runGraphQuery } from "../redux/data";
import type { QueryRequest } from "../redux/dataTypes";
import { NavLink, useLocation, useParams } from "react-router-dom";
import GraphNodeSheet from "../components/GraphNodeSheet";
import GraphNodeProps from "../components/GraphNodeProps";
import GraphNodeBreadCrumb from "../components/GraphNodeBreadcrumb";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import GraphNodeAssocs from "../components/GraphNodeAssocs";

export default function GraphExplorer() {
  const location = useLocation();
  const params = useParams();

  const dispatch = useDispatch();
  const data = useSelector((state: ReduxState) => state.graph.data);
  const [query, setQuery] = useState("g.V()");

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const selectedNode = data?.results.find((node) => node.id === params.id);
  const isDetailView = !!params.id;
  // useEffect(() => {
  //   const queryRequest: QueryRequest = {
  //     query: "g.V(vertexId)",
  //     params: {
  //       vertexId: `${params.id}`,
  //     },
  //     limit: 10,
  //     offset: (page - 1) * pageSize,
  //   };
  //   dispatch(runGraphQuery(queryRequest));
  // }, [params.id]);

  useEffect(() => {
    const queryRequest: QueryRequest = {
      query: params.id ? "g.V(vertexId)" : query,
      params: {
        vertexId: `${params.id}`,
      },
      limit: 10,
      offset: params.id ? 0 : (page - 1) * pageSize,
    };
    dispatch(runGraphQuery(queryRequest));
  }, [page, params.id]);
  const doRunGraphQuery = () => {
    const queryRequest: QueryRequest = {
      query,
      params: {},
      limit: 10,
      offset: (page - 1) * pageSize,
    };
    dispatch(runGraphQuery(queryRequest));
  };

  const totalPages = Math.ceil((data?.total ?? 0) / pageSize);

  return (
    <Box maxHeight="90vh" overflowY="scroll">
      <div
        style={{ display: "flex", justifyContent: "flex-end", margin: "16px" }}
      >
        <Box mr={4} display="inline-flex" flex={1}>
          {/* <FormControl flex={0} minWidth="250px" mr={4}>
            <FormLabel>Root navigation</FormLabel>
            <Select
              placeholder="Select root navigation"
              onChange={(e) => {
                const value = e.target.value;
              }}
            >
              <option value="skills">Skills</option>
              <option value="persons">Persons</option>
            </Select>
          </FormControl> */}
          <FormControl mr={4} flex={1}>
            <FormLabel>Query Editor</FormLabel>
            <Input
              placeholder="Gremlin Query"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
            />
          </FormControl>
          <FormControl mr={4} flex={0}>
            <FormLabel>&nbsp;</FormLabel>
            <Button
              colorScheme="teal"
              leftIcon={<KeyboardReturn />}
              mr={4}
              onClick={doRunGraphQuery}
            >
              Submit
            </Button>
          </FormControl>
        </Box>
      </div>

      {isDetailView &&
        (selectedNode ? (
          <SimpleGrid columns={1} spacing={10}>
            <GraphNodeBreadCrumb node={selectedNode} />
            <GraphNodeSheet node={selectedNode} />
            <GraphNodeProps node={selectedNode} />
            <GraphNodeAssocs node={selectedNode} />
          </SimpleGrid>
        ) : null)}

      {!isDetailView && (
        <Stack direction="row" style={{ margin: "16px" }}>
          <Text>
            Total
            <Badge variant="outline" colorScheme="green" ml={1}>
              {data?.total}
            </Badge>
          </Text>
          <Text>
            Filtered
            <Badge variant="solid" colorScheme="green" ml={1}>
              {data?.results.length}
            </Badge>
          </Text>
        </Stack>
      )}

      {!isDetailView && (
        <Box
          style={{
            borderWidth: "1px",
            borderRadius: "12px",
            borderColor: "#E2E8F0",
          }}
        >
          <Table variant="simple">
            <TableCaption>
              <Stack direction="column">
                <Center>
                  <Stack direction="row">
                    <Button
                      colorScheme="blue"
                      variant="link"
                      isDisabled={page === 1}
                      onClick={() => setPage(page - 1)}
                    >
                      <ChevronLeftIcon />
                    </Button>
                    <Tag colorScheme="teal">
                      Page &nbsp;<strong>{page}</strong>&nbsp; of&nbsp;
                      <strong>{totalPages}</strong>
                    </Tag>
                    <Button
                      colorScheme="blue"
                      variant="link"
                      isDisabled={page === totalPages}
                      onClick={() => setPage(page + 1)}
                    >
                      <ChevronRightIcon />
                    </Button>
                  </Stack>
                </Center>
                <Center>Graph Browser</Center>
              </Stack>
            </TableCaption>
            <Thead>
              <Tr>
                <Th position="sticky" top="0" bg="white">
                  Id
                </Th>
                <Th position="sticky" top="0" bg="white">
                  Label
                </Th>
              </Tr>
            </Thead>

            <Tbody>
              {data?.results.map((node) => (
                <Tr key={node.id}>
                  <Td>
                    <NavLink
                      to={`/data-explorer/${node.id}`}
                      className="sjs-nav-button-rbac"
                    >
                      {node.id}
                    </NavLink>
                  </Td>
                  <Td>{node.label}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      )}
    </Box>
  );
}
