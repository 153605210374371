import React, { useEffect, useState } from "react";
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  IconButton,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { EditIcon, DeleteIcon } from "@chakra-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { ReduxState } from "../redux/root-reducer";
import {
  assocCapacities,
  createRole,
  deleteRole,
  loadCapacities,
  loadRoleCapacities,
  loadRoles,
  updateRole,
} from "../redux/users";
import { Role } from "../redux/userTypes";
import { CapacitiesSelector } from "../components/CapacitiesSelector";
import { ConfirmationModal } from "../components/ConfirmationModal";

export default function Roles() {
  const roles = useSelector((state: ReduxState) => state.users.roles);
  const dispatch = useDispatch();

  const { isOpen, onOpen, onClose } = useDisclosure({ id: "new-role-modal" });
  const {
    isOpen: isOpenCap,
    onOpen: onOpenCap,
    onClose: onCloseCap,
  } = useDisclosure({ id: "new-cap-modal" });
  const {
    isOpen: isOpenDel,
    onOpen: onOpenDel,
    onClose: onCloseDel,
  } = useDisclosure({ id: "del-role-modal" });

  const toast = useToast();

  const [newRole, setNewRole] = useState({ name: "", description: "" });
  const [mode, setMode] = useState("add");
  const [selectedRole, setSelectedRole] = useState(null as Role | null);
  const [associatedCapacities, setAssociatedCapacities] = useState(
    [] as Array<string>
  );
  const viewStatus = useSelector((state: ReduxState) => state.users.status);

  useEffect(() => {
    dispatch(loadCapacities());
    dispatch(loadRoles());
  }, []);

  useEffect(() => {
    const newCurrentRole = roles.find(
      (role: Role) => role.id === selectedRole?.id
    );
    if (newCurrentRole) {
      setSelectedRole(newCurrentRole);
      setAssociatedCapacities(
        newCurrentRole.capacities.map((c: { name: string }) => c.name)
      );
    }
  }, [roles]);

  useEffect(() => {
    if (viewStatus === "succeeded_deleted") {
      onCloseDel();
      onCloseCap();
      onClose();
    }
  }, [viewStatus]);

  function doCreateRole() {
    dispatch(createRole(newRole as Role));
    setNewRole({ name: "", description: "" });
    onClose();
  }

  function doUpdateRole() {
    const updateRoleRequest = {
      id: selectedRole?.id,
      name: newRole.name,
      description: newRole.description,
    };
    dispatch(updateRole(updateRoleRequest as Role));
    setSelectedRole(null);
    setNewRole({ name: "", description: "" });
    onClose();
  }

  function doDeleteRole(role: Role) {
    dispatch(deleteRole(role.id));
    // onCloseDel();
  }

  function doAssocCapacities() {
    // const updatedRoles = roles.map(role =>
    //   role.id === selectedRole?.id
    //     ? { ...role, capacities: associatedCapacities.map(c => ({ name: c }))}
    //     : role
    // );
    if (selectedRole === null) return;
    dispatch(
      assocCapacities({
        id: selectedRole!.id,
        capacities: associatedCapacities,
      })
    );

    setSelectedRole(null);
    setAssociatedCapacities([]);
    onCloseCap();
  }

  const openCapacitiesModal = (role: Role) => {
    setSelectedRole(role);
    dispatch(loadRoleCapacities(role.id));
    onOpenCap();
  };

  const openDeleteModal = (role: Role) => {
    setSelectedRole(role);
    onOpenDel();
  };

  const handleCapacitySelect = (capacity: string, checked: boolean) => {
    console.log(capacity, checked, associatedCapacities);
    if (checked && associatedCapacities.indexOf(capacity) === -1) {
      setAssociatedCapacities([...associatedCapacities, capacity]);
    }
    if (!checked) {
      const index = associatedCapacities.indexOf(capacity);
      if (index !== -1) {
        associatedCapacities.splice(index, 1);
        setAssociatedCapacities([...associatedCapacities]);
      }
    }
  };

  const isSelectedCapacity = (capacity: string) => {
    return associatedCapacities.indexOf(capacity) !== -1;
  };

  const openRoleModal = (mode: string, role: Role | null = null) => {
    setMode(mode);
    if (role !== null) {
      setSelectedRole(role);
      setNewRole({ name: role.name, description: role.description });
    } else {
      setNewRole({ name: "", description: "" });
    }
    onOpen();
  };

  return (
    <Box>
      <Button colorScheme="teal" onClick={() => openRoleModal("add")}>
        Add role
      </Button>

      <Table variant="simple">
        <TableCaption>Roles</TableCaption>
        <Thead>
          <Tr>
            <Th>Name</Th>
            <Th>Actions</Th>
          </Tr>
        </Thead>
        <Tbody>
          {roles.map((role) => (
            <Tr key={role.name}>
              <Td>
                {role.name}: {role.description}
              </Td>
              <Td width={200}>
                <IconButton
                  title="Edit role"
                  aria-label="Edit role"
                  icon={<EditIcon />}
                  marginRight="8px"
                  onClick={() => openRoleModal("edit", role)}
                />
                <IconButton
                  title="Delete role"
                  aria-label="Delete role"
                  icon={<DeleteIcon />}
                  marginRight="8px"
                  onClick={() => openDeleteModal(role)}
                />
                <IconButton
                  title="Associate capacities"
                  aria-label="Associate capacities"
                  icon={<EditIcon />}
                  marginRight="8px"
                  onClick={() => openCapacitiesModal(role)}
                />
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {mode === "add" ? "Add new role" : "Edit role"}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <FormLabel>Name</FormLabel>
              <Input
                value={newRole.name}
                onChange={(e) =>
                  setNewRole({ ...newRole, name: e.target.value })
                }
                placeholder="Enter new role name"
              />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Description</FormLabel>
              <Textarea
                value={newRole.description}
                onChange={(e) =>
                  setNewRole({ ...newRole, description: e.target.value })
                }
                placeholder="Enter role description"
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="blue"
              mr={3}
              onClick={() => (mode === "add" ? doCreateRole() : doUpdateRole())}
            >
              {mode === "add" ? "Add" : "Save"}
            </Button>
            <Button variant="ghost" onClick={onClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isOpenCap} onClose={onCloseCap}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Associate Capacities</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <CapacitiesSelector
              isChecked={isSelectedCapacity}
              onSelect={handleCapacitySelect}
            />
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={doAssocCapacities}>
              Save
            </Button>
            <Button variant="ghost" onClick={onCloseCap}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <ConfirmationModal
        isOpen={isOpenDel}
        onClose={() => onCloseDel()}
        title={"Delete role"}
        message={`Are you sure you want to delete role ${selectedRole?.name}?`}
        onConfirm={() => doDeleteRole(selectedRole as Role)}
      />
    </Box>
  );
}
