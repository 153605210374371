import React, { useEffect, useState } from "react";
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  FormControl,
  FormLabel,
  Input,
  useDisclosure,
} from '@chakra-ui/react';
import { useDispatch, useSelector } from "react-redux";
import { createCapacity, loadCapacities } from "../redux/users";
import { ReduxState } from "../redux/root-reducer";


export default function Capacities() {

  const dispatch = useDispatch();
  const capacities = useSelector((state: ReduxState) => state.users.capacities);

  useEffect(() => {
    dispatch(loadCapacities());
  }, []);


  const { isOpen, onOpen, onClose } = useDisclosure();
  const [newCapacity, setNewCapacity] = useState('');

  const addCapacity = () => {
    dispatch(createCapacity(newCapacity));
    setNewCapacity('');
    onClose();
  };
    
  return (
    <Box>
      
      <Button colorScheme="teal" onClick={onOpen}>
        Add Capacity
      </Button>

      <Table variant="simple">
        <TableCaption>Capacities</TableCaption>
        <Thead>
          <Tr>
            <Th>Capacity</Th>
          </Tr>
        </Thead>
        <Tbody>
          {capacities.map((capacity, index) => (
            <Tr key={index}>
              <Td>{capacity}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add New Capacity</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <FormLabel>Capacity Name</FormLabel>
              <Input
                value={newCapacity}
                onChange={(e) => setNewCapacity(e.target.value)}
                placeholder="Enter capacity name"
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={addCapacity}>
              Add
            </Button>
            <Button variant="ghost" onClick={onClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  )
}