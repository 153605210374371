import {
  Box,
  Button,
  Card,
  CardHeader,
  Center,
  Heading,
  SimpleGrid,
  Stack,
  Table,
  TableCaption,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { getAssocs } from "../redux/data";
import type { ReduxState } from "../redux/root-reducer";
import type { GraphNodeProps } from "./graphNodeTypes";

export default function GraphNodeAssocs({ node }: GraphNodeProps) {
  const dispatch = useDispatch();
  const data = useSelector((state: ReduxState) => state.graph.assocs);
  const sourceAssocs =
    data?.filter((assoc) => assoc.outVertexId === node.id) ?? [];
  const targetAssocs =
    data?.filter((assoc) => assoc.inVertexId === node.id) ?? [];

  useEffect(() => {
    dispatch(getAssocs(node.id));
  }, [node.id]);

  // Columns for the table:
  // id: string;
  // label: string;
  // type: string;
  // inVertexId: string;
  // outVertexId: string;
  // inVertexLabel: string;
  // outVertexLabel: string;
  return (
    <SimpleGrid columns={2} spacing={10}>
      <Card
        style={{
          borderWidth: "1px",
          borderRadius: "12px",
          borderColor: "#E2E8F0",
        }}
      >
        <CardHeader>
          <Heading size="sm">Source Node Assocs</Heading>
        </CardHeader>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th position="sticky" top="0" bg="white">
                Assoc
              </Th>
              <Th position="sticky" top="0" bg="white">
                Target Node
              </Th>
            </Tr>
          </Thead>

          <Tbody>
            {sourceAssocs?.map((node) => (
              <Tr key={node.id}>
                <Td>
                  <Stack>
                    <Text>{node.label}</Text>
                    <Text fontSize="sm">
                      Id: {node.id} / Type: {node.type}
                    </Text>
                  </Stack>
                </Td>
                <Td width={"50%"}>
                  <NavLink to={`/data-explorer/${node.inVertexId}`}>
                    <Stack>
                      <Text>{node.inVertexLabel}</Text>
                      <Text fontSize={"sm"}>Id: {node.inVertexId}</Text>
                    </Stack>
                  </NavLink>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Card>
      <Card
        style={{
          borderWidth: "1px",
          borderRadius: "12px",
          borderColor: "#E2E8F0",
        }}
      >
        <CardHeader>
          <Heading size="sm">Target Node Assocs</Heading>
        </CardHeader>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th position="sticky" top="0" bg="white">
                Assoc
              </Th>
              <Th position="sticky" top="0" bg="white">
                Source Node
              </Th>
            </Tr>
          </Thead>

          <Tbody>
            {targetAssocs?.map((node) => (
              <Tr key={node.id}>
                <Td>
                  <Stack>
                    <Text>{node.label}</Text>
                    <Text fontSize="sm">
                      Id: {node.id} / Type: {node.type}
                    </Text>
                  </Stack>
                </Td>
                <Td width={"50%"}>
                  <NavLink
                    to={`/data-explorer/${node.outVertexId}`}
                    className="sjs-nav-button-rbac"
                  >
                    <Stack>
                      <Text>{node.outVertexLabel}</Text>
                      <Text fontSize="sm">Id: {node.outVertexId}</Text>
                    </Stack>
                  </NavLink>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Card>
    </SimpleGrid>
  );
}
