import { AccountInfo } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import React, { useEffect, useState } from "react";
import {
  Location,
  NavLink,
  useLocation,
  useNavigate,
  useRoutes,
} from "react-router-dom";
import logo from "../logo.svg";
import { AccountCircle } from "@mui/icons-material";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import {
  Box,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Text,
  Portal,
} from "@chakra-ui/react";

const routePaths = [
  { path: "/", exact: true },
  { path: "/capacities", exact: true },
  { path: "/waiting-list", exact: true },
  { path: "/data-explorer", exact: false },
];

const indexFromLocation = (location: Location) => {
  return routePaths.findIndex((path) => {
    if (path.exact) {
      return location.pathname === path.path;
    }
    return location.pathname.startsWith(path.path);
  });
};

const locationFromIndex = (index: number) => {
  return routePaths[index].path;
};

const getUserInfo = (
  account: AccountInfo | null
): { name: string; email: string } | null => {
  if (!account) return null;
  return {
    name: account?.name ?? "Unknown User",
    email: (account?.idTokenClaims?.emails ?? [])[0] ?? "Unknown Email",
  };
};

export function NavBar() {
  const [currentToken, setCurrentToken] = useState(
    localStorage.getItem("jwtToken")
  );
  const { instance } = useMsal();
  const [tabIndex, setTabIndex] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    setTabIndex(indexFromLocation(location));
  }, [location]);

  const handleLogout = () => {
    instance.logoutRedirect();
    window.location.href = "/";
  };

  const handleTabsChange = (index: number) => {
    navigate(locationFromIndex(index));
  };

  const userInfo = getUserInfo(instance?.getActiveAccount());
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: "16px",
        }}
      >
        <div style={{ display: "flex", flex: 1 }}>
          <img
            src={logo}
            className="sjs-client-app__logo"
            alt="logo"
            height={"40px"}
          />

          <Tabs
            index={tabIndex}
            onChange={handleTabsChange}
            width="100%"
            flexGrow={1}
          >
            <TabList>
              <Tab>Templates</Tab>
              <Tab>RBAC</Tab>
              <Tab>Waiting List</Tab>
              <Tab>Data Explorer</Tab>
            </TabList>
          </Tabs>
        </div>

        {userInfo && (
          <Box display="flex" justifyContent="flex-end">
            <Menu>
              <MenuButton as={IconButton} icon={<AccountCircle />} />
              <Portal>
                <MenuList
                  transformOrigin="top right"
                  zIndex="dropdown"
                  boxShadow="sm"
                  mt="1.5"
                  borderRadius="sm"
                  overflow="visible"
                  position="relative"
                  _before={{
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bg: "white",
                    zIndex: -1,
                    transform: "translateY(-50%) rotate(45deg)",
                    boxShadow: "0px 2px 8px rgba(0,0,0,0.32)",
                  }}
                >
                  <MenuItem>
                    <Text fontSize="md">
                      {userInfo.name} ({userInfo.email})
                    </Text>
                  </MenuItem>
                  <MenuItem onClick={handleLogout}>Logout</MenuItem>
                </MenuList>
              </Portal>
            </Menu>
          </Box>
        )}
      </div>
    </>
  );
}
