import {
  Box,
  Card,
  CardBody,
  CardHeader,
  Heading,
  Stack,
  StackDivider,
  Text,
} from "@chakra-ui/react";
import type { GraphNodeProps } from "./graphNodeTypes";

export default function GraphNodeSheet({ node }: GraphNodeProps) {
  const nodeType = node.properties.type ? node.properties.type[0].value : "";
  const nodeTitle = node.properties.title ? node.properties.title[0].value : "";
  return (
    <Card
      style={{
        borderWidth: "1px",
        borderRadius: "12px",
        borderColor: "#E2E8F0",
      }}
    >
      <CardHeader>
        <Heading size="sm">Node Sheet</Heading>
      </CardHeader>
      <CardBody>
        <Box>
          <Stack divider={<StackDivider />}>
            <Text pt="2">Label: {node.label}</Text>
            <Text pt="2">Type: {nodeType}</Text>
            <Text pt="2">Title: {nodeTitle}</Text>
          </Stack>
        </Box>
      </CardBody>
    </Card>
  );
}
