import React from "react";
import PropTypes from "prop-types";
import { HuggaImageRadio, HuggaImageRadioOption } from "hugga-reactjs-front-library";
import { EmojiPeople } from '@mui/icons-material';
import { ElementFactory, Question, Serializer, SvgRegistry } from "survey-core";
import { SurveyQuestionElementBase, ReactQuestionFactory } from "survey-react-ui";
import { PropertyGridEditorCollection } from "survey-creator-react";
import { localization } from "survey-creator-core";
import ReactDOMServer from "react-dom/server";
import { FunctionFactory } from "survey-core";

export const PERSONALITY_TRAIT_TYPE = "personality-trait";
export class PersonalityTraitModel extends Question {
    getType() {
      return PERSONALITY_TRAIT_TYPE;
    }

    set scoring(val) {
        this.setPropertyValue("scoring", val);
    }

    get scoring() {
        return this.getPropertyValue("scoring");
    }

    set option1Type(val) {
        this.setPropertyValue("option1Type", val);
    }

    get option1Type() {
        return this.getPropertyValue("option1Type");
    }

    set option1Answer(val) {
        this.setPropertyValue("option1Answer", val);
    }

    get option1Answer() {
        return this.getPropertyValue("option1Answer");
    }

    set option1ImageUrl(val) {
        this.setPropertyValue("option1ImageUrl", val);
    }

    get option1ImageUrl() {
        return this.getPropertyValue("option1ImageUrl");
    }

    set option2Type(val) {
        this.setPropertyValue("option2Type", val);
    }

    get option2Type() {
        return this.getPropertyValue("option2Type");
    }

    set option2Answer(val) {
        this.setPropertyValue("option2Answer", val);
    }

    get option2Answer() {
        return this.getPropertyValue("option2Answer");
    }

    set option2ImageUrl(val) {
        this.setPropertyValue("option2ImageUrl", val);
    }

    get option2ImageUrl() {
        return this.getPropertyValue("option2ImageUrl");
    }
}

export function registerHuggaPersonalityTrait() {
    ElementFactory.Instance.registerElement(PERSONALITY_TRAIT_TYPE, (name) => {
        return new PersonalityTraitModel(name);
    });
}

const locale = localization.getLocale("");
locale.qt[PERSONALITY_TRAIT_TYPE] = "Personality Trait";
locale.pe.value = "Value";

const svg = ReactDOMServer.renderToString(<EmojiPeople />);
SvgRegistry.registerIconFromSvg(PERSONALITY_TRAIT_TYPE, svg);

Serializer.addClass(
    PERSONALITY_TRAIT_TYPE,
    [
        {
            name: "scoring",
            choices: [
                "Extraversion - Introversion (EI)",
                "Sensing - Intuition (SN)",
                "Thinking - Feeling (TF)",
                "Judging - Perceiving (JP)",
            ],
            category: PERSONALITY_TRAIT_TYPE,
            title: "Scoring",
            isRequired: true,
            onSetValue: function (obj, value) {
                obj.scoring = value;
            }
        },
        {
            name: "option1Type",
            title: "Option1 Type",
            isRequired: true,
            category: PERSONALITY_TRAIT_TYPE,
            choices: [
                "Extraversion (E)",
                "Introversion (I)",
                "Sensing (S)",
                "Intuition (N)",
                "Thinking (T)",
                "Feeling (F)",
                "Judging (J)",
                "Perceiving (P)",
            ],
            onSetValue: function (obj, value) {
                obj.option1Type = value;
            }
        },
        {
            name: "option1Answer",
            title: "Option1 Answer",
            category: PERSONALITY_TRAIT_TYPE,
            onSetValue: function (obj, value) {
                obj.option1Answer = value;
            }
        },
        {
            name: "option1ImageUrl",
            title: "Option1 Image Url",
            category: PERSONALITY_TRAIT_TYPE,
            onSetValue: function (obj, value) {
                obj.option1ImageUrl = value;
            }
        },
        {
            name: "option2Type",
            title: "Option2 Type",
            category: PERSONALITY_TRAIT_TYPE,
            choices: [
                "Extraversion (E)",
                "Introversion (I)",
                "Sensing (S)",
                "Intuition (N)",
                "Thinking (T)",
                "Feeling (F)",
                "Judging (J)",
                "Perceiving (P)",
            ],
            onSetValue: function (obj, value) {
                obj.option2Type = value;
            }
        },
        {
            name: "option2Answer",
            title: "Option2 Answer",
            category: PERSONALITY_TRAIT_TYPE,
            onSetValue: function (obj, value) {
                obj.option2Answer = value;
            }
        },
        {
            name: "option2ImageUrl",
            title: "Option2 Image Url",
            category: PERSONALITY_TRAIT_TYPE,
            onSetValue: function (obj, value) {
                obj.option2ImageUrl = value;
            }
        },
    ],
    function () {
        const model = new PersonalityTraitModel("");
        model.options = [];
        return model;
    },
    "question"
);
export class HuggaPersonalityTraitQuestion extends SurveyQuestionElementBase {
    constructor(props) {
        super(props);
        if(this.question.value === undefined) {
            this.question.value = 1;
        }
        this.state = { value: this.question.value };
        
    }

    get question() {
        return this.questionBase;
    }
    
    get value() {
        return this.question.value;
    }

    set value(val) {
        this.question.value = val;
    }

    get type() {
        console.log('PERSONALITY_TRAIT_TYPE', this);
        return PERSONALITY_TRAIT_TYPE;
    }

    handleChange = (data) => {
        this.question.value = data;
        this.setState({ value: data });
    };
      
    renderElement() {
        return (
          <div style={this.style}>
            <HuggaImageRadio defaultValue={this.question.value} onSelected={val => this.handleChange(val)}>
                <HuggaImageRadioOption 
                    label={this.question.option1Answer} 
                    src={this.question.option1ImageUrl}
                />
                <HuggaImageRadioOption 
                    label={this.question.option2Answer}  
                    src={this.question.option2ImageUrl}
                />
            </HuggaImageRadio>
          </div>
        );
    }
}

function findQuestionsScoringEndsWith(instance, suffix) {
    let total = 0;
    instance.survey.getAllQuestions().forEach(question => {
        let scoringOption = question.getPropertyValue("option1Type") || "";
        if (question.value === 1) {
            scoringOption = question.getPropertyValue("option2Type")
        }
        if (scoringOption.endsWith(suffix)) {
            total++;
        }
    });
    return total;
}

export function calculateProfile() {
    let profile = "";

    const totalE = findQuestionsScoringEndsWith(this, "(E)");
    const totalI = findQuestionsScoringEndsWith(this, "(I)");

    const totalS = findQuestionsScoringEndsWith(this, "(S)")
    const totalN = findQuestionsScoringEndsWith(this, "(N)")

    const totalT = findQuestionsScoringEndsWith(this, "(T)");
    const totalF = findQuestionsScoringEndsWith(this, "(F)");

    const totalJ = findQuestionsScoringEndsWith(this, "(J)");
    const totalP = findQuestionsScoringEndsWith(this, "(P)");

    profile += totalE > totalI ? "E" : "I";
    profile += totalS > totalN ? "S" : "N";
    profile += totalT > totalF ? "T" : "F";
    profile += totalJ > totalP ? "J" : "P";

    return profile;
}

FunctionFactory.Instance.register("calculateProfile", calculateProfile);

ReactQuestionFactory.Instance.registerQuestion(PERSONALITY_TRAIT_TYPE, (props) => {
    return React.createElement(HuggaPersonalityTraitQuestion, props);
});

PropertyGridEditorCollection.register({
    fit: function (prop) {
      return prop.type === PERSONALITY_TRAIT_TYPE;
    },
    getJSON: function () {
      return {
        type: PERSONALITY_TRAIT_TYPE,
      };
    }
});
