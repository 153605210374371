import React, { useState } from "react";
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  IconButton,
  useDisclosure,
  VStack,
  Checkbox,
} from '@chakra-ui/react';
import { EditIcon, DeleteIcon } from "@chakra-ui/icons";
import { PersonAdd, GroupAdd } from "@mui/icons-material";

type Group = {
  id: number;
  name: string;
  description: string;
  users: any[];
  groups: Group[];
};

function CapacitiesSelector({ capacities, onSelect, isChecked }: any) {
  const [search, setSearch] = useState('');

  const filteredCapacities = capacities.filter((capacity: string) =>
    capacity.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <VStack align="start" spacing={2}>
      <Input
        placeholder="Search capacities"
        value={search}
        onChange={e => setSearch(e.target.value)}
      />
      {filteredCapacities.map((capacity: any) => (
        <Checkbox 
          key={capacity} 
          onChange={(e: any) => onSelect(capacity, e.target.checked)} 
          isChecked={isChecked(capacity)}>
          {capacity}
        </Checkbox>
      ))}
    </VStack>
  );
}


export default function Groups() {
  const [groups, setGroups] = useState([
    { id: 1, name: 'Surveys Editors', description: 'Manage surveys', users: [], groups: [] },
    { id: 2, name: 'Surveys Viewers', description: 'Manage users',  users: [], groups: [] },
  ] as Group[]);

  const { isOpen, onOpen, onClose } = useDisclosure({id: "new-role-modal"});
  const { isOpen: isOpenCap, onOpen: onOpenCap, onClose: onCloseCap } = useDisclosure({id: "new-cap-modal"});
  const [newGroup, setNewGroup] = useState({ name: '', description: '' });
  const [mode, setMode] = useState('add');
  const [currentRole, setCurrentGroup] = useState(null as Group | null);

  const addGroup = () => {
    const id = groups.length + 1;
    setGroups([...groups, { id, ...newGroup, users: [], groups: [] }]);
    setNewGroup({ name: '', description: '' });
    onClose();
  };

  const updateGroup = () => {
    const updatedRoles = groups.map(role =>
      role.id === currentRole?.id ? { ...role, ...newGroup } : role
    );
    setGroups(updatedRoles);
    setCurrentGroup(null);
    setNewGroup({ name: '', description: '' });
    onClose();
  };

  const [selectedRole, setSelectedRole] = useState(null as any);
  const [associatedCapacities, setAssociatedCapacities] = useState([] as Array<string>);

  const openCapacitiesModal = (role: Group) => {
    // setSelectedRole(role);
    // setAssociatedCapacities(role.capacities);
    // onOpenCap();
  };

  const openGroupModal = (mode: string, group: Group|null = null) => {
    setMode(mode);
    if(group !== null){
      setCurrentGroup(group);
      setNewGroup({ name: group.name, description: group.description });
    }
    else {
      setNewGroup({ name: '', description: '' });
    }
    onOpen();
  };

  return (
    <Box>
      <Button colorScheme="teal" onClick={() => openGroupModal('add')}>
        Add group
      </Button>

      <Table variant="simple">
        <TableCaption>Groups</TableCaption>
        <Thead>
          <Tr>
            <Th>ID</Th>
            <Th>Name</Th>
            <Th>Actions</Th>
          </Tr>
        </Thead>
        <Tbody>
          {groups.map(group => (
            <Tr key={group.id}>
              <Td width={50}>{group.id}</Td>
              <Td>{group.name}: {group.description}</Td>
              <Td width={200}>
                <IconButton 
                  title="Edit group" 
                  aria-label="Edit group" 
                  icon={<EditIcon />} 
                  marginRight="8px" 
                  onClick={() => openGroupModal('edit', group)}/>
                
                <IconButton 
                  title="Add users" 
                  aria-label="Add users" 
                  icon={<PersonAdd />} marginRight="8px" 
                  onClick={ () => openCapacitiesModal(group) } />
                {/* <IconButton 
                  title="Add subgroups" 
                  aria-label="Add subgroups" 
                  icon={<GroupAdd />} marginRight="8px" 
                  onClick={ () => openCapacitiesModal(group) } /> */}
                <IconButton 
                  title="Delete group" 
                  aria-label="Delete group" 
                  icon={<DeleteIcon />} 
                  marginRight="8px" 
                  onClick={() => setGroups(groups.filter(r => r.id !== group.id)) } />
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            { mode === 'add' ? 'Add new group' : 'Edit group' }
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <FormLabel>Name</FormLabel>
              <Input
                value={newGroup.name}
                onChange={(e) => setNewGroup({ ...newGroup, name: e.target.value })}
                placeholder="Enter new group name"
              />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Description</FormLabel>
              <Textarea
                value={newGroup.description}
                onChange={(e) => setNewGroup({ ...newGroup, description: e.target.value })}
                placeholder="Enter group description"
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={() => mode === 'add' ? addGroup() : updateGroup()}>
              { mode === 'add' ? 'Add' : 'Save' }
            </Button>
            <Button variant="ghost" onClick={onClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  )
}
