import { createSlice } from "@reduxjs/toolkit";
import { newAsyncThunk } from "./common";
import { UserApi } from "./userApi";
import { QueryRequest, ReduxDataState } from "./dataTypes";
import { DataApi } from "./dataApi";

const apiBaseAddress =
  process.env.REACT_APP_API_SKILL ||
  "https://t-hugga-ms-skill.azurewebsites.net/api/v1";

// const apiBaseAddress =
//   process.env.REACT_APP_API_SKILL || "http://localhost:8080/api/v1";

const initialState: ReduxDataState = {
  status: "idle",
  error: null,
};

const dataSlice = createSlice({
  name: "data",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(runGraphQuery.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(runGraphQuery.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      })
      .addCase(runGraphQuery.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(getAssocs.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getAssocs.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.assocs = action.payload;
      })
      .addCase(getAssocs.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const dataApi = new DataApi(apiBaseAddress);

export const runGraphQuery = newAsyncThunk(
  "data-explorer/run-query",
  (token: string, request: QueryRequest) =>
    dataApi.runGraphQuery(token, request)
);

export const getAssocs = newAsyncThunk(
  "data-explorer/get-assocs",
  (token: string, vertexId: string) => dataApi.getAssocs(token, vertexId)
);

// export const { add, remove, update } = surveysSlice.actions
export default dataSlice.reducer;
