import React, { useEffect, useState } from "react";
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  Button,
  useDisclosure,
  IconButton,
  Spinner,
  Input,
  Select,
  Stack,
  Badge,
  Text,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import {
  loadWaitingListUsers,
  addWaitingListUser,
  inviteWaitingListUser,
} from "../redux/users";
import { ReduxState } from "../redux/root-reducer";
import { Role, User, WaitingListUser } from "../redux/userTypes";
import AddWaitingListUserModal from "../components/AddWaitingListUserModal";
import ShareIcon from "@mui/icons-material/Share";
import SendIcon from "@mui/icons-material/Send";
import { SettingsIcon } from "@chakra-ui/icons";

const AVATAR_BASE_URL =
  "https://devhuggafrontapp.blob.core.windows.net/images/";

export default function WaitingList() {
  const dispatch = useDispatch();
  const users = useSelector(
    (state: ReduxState) => state.users.waitingListUsers
  );
  const meta = useSelector((state: ReduxState) => state.users.statusMeta);
  const currentType = useSelector(
    (state: ReduxState) => state.users.statusType
  );
  const userRoles = useSelector((state: ReduxState) => state.users.userRoles);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [filter, setFilter] = useState("");
  const [isInvitedFilter, setIsInvitedFilter] = useState(
    null as boolean | null
  );
  const [isConfirmedFilter, setIsConfirmedFilter] = useState(
    null as boolean | null
  );

  useEffect(() => {
    dispatch(loadWaitingListUsers());
  }, []);

  const filteredUsers = users.filter((user: WaitingListUser) => {
    const emailFilter = user.email.toLowerCase().includes(filter.toLowerCase());

    if (isInvitedFilter === null && isConfirmedFilter === null) {
      return emailFilter;
    }

    if (isInvitedFilter === null) {
      return emailFilter && user.isConfirmed === isConfirmedFilter;
    }

    if (isConfirmedFilter === null) {
      return emailFilter && user.isInvited === isInvitedFilter;
    }

    return (
      emailFilter &&
      user.isInvited === isInvitedFilter &&
      user.isConfirmed === isConfirmedFilter
    );
  });

  // useEffect(() => {
  // 	if (currentType ===
  // 	"waiting-list-users/invite/pending") {
  // 		dispatch(loadWaitingListUsers());
  // 	}

  const doAddUser = (newUser: WaitingListUser) => {
    console.log(newUser);
    dispatch(addWaitingListUser(newUser));
    onClose();
  };

  const doInviteUser = (user: WaitingListUser) => {
    dispatch(inviteWaitingListUser(user));
  };

  const formattedDate = (date?: string | Date | null) => {
    if (!date) {
      return "-";
    }
    const d = new Date(date);
    const formatter = new Intl.DateTimeFormat("es-CL", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
    });
    return formatter.format(d);
  };

  return (
    <Box maxHeight="90vh" overflowY="scroll">
      <div
        style={{ display: "flex", justifyContent: "flex-end", margin: "16px" }}
      >
        <Box mr={4} display="inline-flex" flex={1}>
          <Input
            mr={4}
            placeholder="Filter by email"
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
          />
          <Select
            placeholder="Filter by status"
            mr={4}
            onChange={(e) => {
              const value = e.target.value;
              if (value === "isInvited") {
                setIsInvitedFilter(true);
                setIsConfirmedFilter(null);
              } else if (value === "isConfirmed") {
                setIsConfirmedFilter(true);
              } else {
                setIsInvitedFilter(null);
                setIsConfirmedFilter(null);
              }
            }}
          >
            <option value="isInvited">Is Invited</option>
            <option value="isConfirmed">Is Confirmed</option>
          </Select>
        </Box>
        <Button colorScheme="teal" onClick={onOpen} mr={4}>
          Add user
        </Button>
        <IconButton
          mr={2}
          colorScheme="gray"
          aria-label="Configure Invitation Link"
          icon={<SettingsIcon />}
          onClick={() => {}}
        />
      </div>

      <Stack direction="row" style={{ margin: "16px" }}>
        <Text>
          Total
          <Badge variant="outline" colorScheme="green" ml={1}>
            {users.length}
          </Badge>
        </Text>
        <Text>
          Filtered
          <Badge variant="solid" colorScheme="green" ml={1}>
            {filteredUsers.length}
          </Badge>
        </Text>
      </Stack>

      <Table variant="simple">
        <TableCaption>Users</TableCaption>
        <Thead>
          <Tr>
            <Th position="sticky" top="0" bg="white">
              Email
            </Th>
            <Th position="sticky" top="0" bg="white">
              Is Invited
            </Th>
            <Th position="sticky" top="0" bg="white">
              Is Confirmed
            </Th>
            <Th position="sticky" top="0" bg="white">
              Invitation Date
            </Th>
            <Th position="sticky" top="0" bg="white">
              Confirmation Date
            </Th>
            <Th position="sticky" top="0" bg="white">
              Confirmation Code
            </Th>
            <Th position="sticky" top="0" bg="white">
              Actions
            </Th>
          </Tr>
        </Thead>

        <Tbody>
          {filteredUsers.map((user) => (
            <Tr key={user.email}>
              <Td>
                <a
                  style={{ textDecoration: "underline" }}
                  href={`mailto:${user.email}`}
                >
                  {user.email}
                </a>
                <div>
                  <small>{user.name}</small>
                </div>
                <div>
                  <small>{user.phone}</small>
                </div>
              </Td>
              <Td>{user.isInvited ? "Yes" : "No"}</Td>
              <Td>{user.isConfirmed ? "Yes" : "No"}</Td>
              <Td>{formattedDate(user.invitationDate)}</Td>
              <Td>{formattedDate(user.confirmationDate)}</Td>
              <Td>
                <a
                  style={{ textDecoration: "underline" }}
                  href={user.confirmationCode}
                >
                  {user.confirmationCode}
                </a>
              </Td>
              <Td>
                {!user.isInvited &&
                  (currentType === "waiting-list-users/invite/pending" &&
                  meta.arg.email === user.email ? (
                    <Spinner />
                  ) : (
                    <Button
                      position="static"
                      title="Invite user"
                      aria-label="Invite user"
                      marginRight="8px"
                      colorScheme="blue"
                      variant="outline"
                      size="sm"
                      leftIcon={<ShareIcon />}
                      onClick={() => doInviteUser(user)}
                    >
                      Send invitation
                    </Button>
                  ))}

                {user.isInvited &&
                  !user.isConfirmed &&
                  (currentType === "waiting-list-users/invite/pending" &&
                  meta.arg.email === user.email ? (
                    <Spinner />
                  ) : (
                    <Button
                      position="static"
                      title="Resend invitation"
                      aria-label="Resend invitation"
                      marginRight="8px"
                      size="sm"
                      colorScheme="teal"
                      variant="outline"
                      leftIcon={<SendIcon />}
                      onClick={() => doInviteUser(user)}
                    >
                      Resend invitation
                    </Button>
                  ))}
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>

      <AddWaitingListUserModal
        isOpen={isOpen}
        onClose={onClose}
        onConfirm={doAddUser}
      />
    </Box>
  );
}
