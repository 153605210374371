import { Center, Checkbox, CircularProgress, Input, VStack } from "@chakra-ui/react";
import React, {useState} from "react";
import { useSelector } from "react-redux";
import { ReduxState } from "../redux/root-reducer";

export function CapacitiesSelector({ onSelect, isChecked }: any) {
  
    const [search, setSearch] = useState('');
    const capacities = useSelector((state: ReduxState) => state.users.capacities);
    const viewStatus = useSelector((state: ReduxState) => state.users.status);
    const filteredCapacities = capacities.filter((capacity: string) =>
      capacity.toLowerCase().includes(search.toLowerCase())
    );
  
    return (
      <VStack align="start" spacing={2}>
        <Input
          placeholder="Search capacities"
          value={search}
          onChange={e => setSearch(e.target.value)}
        />
        {viewStatus === 'loading' && 
          <div style={{width:"100%"}}>
            <Center>
               <CircularProgress isIndeterminate />
            </Center>
          </div>
        }
        {viewStatus !== 'loading' && filteredCapacities.map((capacity: any) => (
          <Checkbox 
            key={capacity} 
            onChange={(e: any) => onSelect(capacity, e.target.checked)} 
            isChecked={isChecked(capacity)}>
            {capacity}
          </Checkbox>
        ))}
      </VStack>
    );
  }