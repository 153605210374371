import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Box,
  Heading,
  Text,
  FormControl,
  FormLabel,
  Textarea,
  Button,
} from "@chakra-ui/react";

import Logo from "../logo.svg";
import LoginButton from "../components/LoginButton";

const Login = () => {
  return (
    <Container maxW="container.sm">
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
        alignItems="center"
        minHeight="100vh"
        mt={6}
      >
        <LoginButton />
      </Box>
    </Container>
  );
};

export default Login;
