import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { redirect } from "react-router";

export type ReduxAuthState = {
  authError: boolean;
  error: any;
};

const initialState: ReduxAuthState = {
  authError: false,
  error: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},

  extraReducers(builder) {
    builder.addCase("auth/cleanError", (state, action) => {
      state.error = null;
    });

    builder.addDefaultCase((state, action) => {
      if (action.type.endsWith("/rejected")) {
        analyzeError(action.error, state);
      } else {
        console.error(action);
        state.error = action.error;
      }
    });
  },
});

function analyzeError(error: any, state: any) {
  if (error.name == "InteractionRequiredAuthError") {
    state.authError = true;
    redirect("/login");
  }
}

export const cleanError = () => ({
  type: "auth/cleanError",
});

export default authSlice.reducer;
