import { AuthenticationResult } from "@azure/msal-browser";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { msalInstance } from "../index";
import axios, { AxiosResponse } from "axios";

export async function getAccessToken(): Promise<AuthenticationResult | null> {
  const accessToken = await msalInstance.acquireTokenSilent({
    scopes: ["https://huggadev.onmicrosoft.com/tasks-api/task.read"],
  });
  return accessToken;
}

export function newAsyncThunk<Returned, ThunkArg = void>(
  name: string,
  asyncFn: (token: string, args: ThunkArg, thunkAPI: any) => Promise<Returned>
) {
  return createAsyncThunk<Returned, ThunkArg>(name, async (args, thunkAPI) => {
    const accessToken = await getAccessToken();
    if (accessToken) {
      return await asyncFn(accessToken.accessToken, args, thunkAPI);
    }
    return null as Returned;
  });
}

export function headers(token: string) {
  return { Authorization: `Bearer ${token}` };
}

export function jsonHeaders(token: string) {
  return { ...headers(token), "Content-Type": "application/json" };
}

export async function get<ApiResponse>(
  url: string,
  token: string
): Promise<ApiResponse> {
  const response = await axios.get<ApiResponse>(url, {
    headers: headers(token),
  });
  return response.data;
}

export async function post<ApiRequest, ApiResponse>(
  url: string,
  token: string,
  data: ApiRequest
): Promise<ApiResponse> {
  const response = await axios.post<ApiRequest, AxiosResponse<ApiResponse>>(
    url,
    data,
    {
      headers: jsonHeaders(token),
    }
  );
  return response.data;
}

export async function put<ApiRequest, ApiResponse>(
  url: string,
  token: string,
  data: ApiRequest
): Promise<ApiResponse> {
  const response = await axios.put<ApiRequest, AxiosResponse<ApiResponse>>(
    url,
    data,
    { headers: jsonHeaders(token) }
  );
  return response.data;
}

export async function del<ApiRequest, ApiResponse>(
  url: string,
  token: string
): Promise<ApiResponse> {
  const response = await axios.delete<ApiRequest, AxiosResponse<ApiResponse>>(
    url,
    { headers: headers(token) }
  );
  return response.data;
}

export async function patch<ApiRequest, ApiResponse>(
  url: string,
  token: string,
  data: ApiRequest
): Promise<ApiResponse> {
  const response = await axios.patch<ApiRequest, AxiosResponse<ApiResponse>>(
    url,
    data,
    {
      headers: jsonHeaders(token),
    }
  );
  return response.data;
}
