import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Content from "./routes/routes";

import store from "./redux";
import "./App.css";
import { MsalProvider } from "@azure/msal-react";
import { msalInstance } from "./index";

function App() {
  return (
    <Provider store={store}>
      <MsalProvider instance={msalInstance}>
        <Router>
          <Content />
        </Router>
      </MsalProvider>
    </Provider>
  );
}

export default App;
