import { Stack, Text } from "@chakra-ui/react";
import { NavLink } from "react-router-dom";
import type { QueryNodeModel } from "../redux/dataTypes";
import { GraphNodeProps } from "./graphNodeTypes";

export default function GraphNodeBreadCrumb({ node }: GraphNodeProps) {
  return (
    <Stack direction="row" style={{ margin: "16px" }}>
      <Text>
        <NavLink to="/data-explorer" className="sjs-nav-button-rbac">
          Root
        </NavLink>
      </Text>
      <Text>/</Text>
      <Text>{node.id}</Text>
    </Stack>
  );
}
