import React, { useEffect, useState } from "react";
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  FormControl,
  FormLabel,
  Input,
  useDisclosure,
  AvatarBadge,
  Avatar,
  IconButton,
  VStack,
  Checkbox,
  CircularProgress,
  Center,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import {
  assocRoles,
  loadRoles,
  loadUserRoles,
  loadUsers,
} from "../redux/users";
import { ReduxState } from "../redux/root-reducer";
import ReduceCapacityIcon from "@mui/icons-material/ReduceCapacity";
import { Role, User } from "../redux/userTypes";

const AVATAR_BASE_URL =
  "https://devhuggafrontapp.blob.core.windows.net/images/";

function RolesSelector({ onSelect, isChecked }: any) {
  const [search, setSearch] = useState("");
  const roles = useSelector((state: ReduxState) => state.users.roles);
  const viewStatus = useSelector((state: ReduxState) => state.users.status);

  const filteredRoles = roles.filter((role: Role) =>
    role.name.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <VStack align="start" spacing={2}>
      <Input
        placeholder="Search roles"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />
      {viewStatus === "loading" && (
        <div style={{ width: "100%" }}>
          <Center>
            <CircularProgress isIndeterminate />
          </Center>
        </div>
      )}
      {viewStatus !== "loading" &&
        filteredRoles.map((role) => (
          <Checkbox
            key={role.id}
            onChange={(e: any) => onSelect(role, e.target.checked)}
            isChecked={isChecked(role)}
          >
            {role.name}
          </Checkbox>
        ))}
    </VStack>
  );
}

export default function Users() {
  const dispatch = useDispatch();
  const users = useSelector((state: ReduxState) => state.users.users);
  const userRoles = useSelector((state: ReduxState) => state.users.userRoles);
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedRoles, setSelectedRoles] = useState<Role[]>([]);

  function fullName(): string {
    if (!currentUser) return "";
    return `${currentUser?.names} ${currentUser?.lastnames}`;
  }

  useEffect(() => {
    dispatch(loadRoles());
    dispatch(loadUsers());
  }, []);

  useEffect(() => {
    if (!currentUser) return;
    setSelectedRoles([...userRoles[currentUser?._id]]);
  }, [userRoles]);

  const openRoleModal = (user: User) => {
    setCurrentUser(user);
    dispatch(loadUserRoles(user._id));
    onOpen();
  };

  const isSelectedRole = (role: Role) => {
    if (!currentUser) return false;
    return (
      selectedRoles.findIndex((r) => {
        return r.id === role.id;
      }) !== -1
    );
  };

  const handleRoleSelect = (role: Role, checked: boolean) => {
    if (!currentUser) return;
    if (checked) {
      selectedRoles.push(role);
    } else {
      selectedRoles.splice(selectedRoles.indexOf(role), 1);
    }
    setSelectedRoles([...selectedRoles]);
  };

  const doAssocRoles = () => {
    if (!currentUser) return;
    console.log(selectedRoles);
    dispatch(assocRoles({ userId: currentUser._id, roles: selectedRoles }));
    onClose();
  };

  return (
    <Box>
      <Table variant="simple">
        <TableCaption>Users</TableCaption>
        <Thead>
          <Tr>
            <Th>Names</Th>
            <Th>Email</Th>
            <Th>Issuer</Th>
            <Th>Birthdate</Th>
            <Th>Actions</Th>
          </Tr>
        </Thead>
        <Tbody>
          {users.map((user) => (
            <Tr key={user._id}>
              <Td>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Avatar
                    name={fullName()}
                    src={`${AVATAR_BASE_URL}${user.image_url}`}
                  />
                  <div style={{ marginLeft: "8px" }}>
                    <div>
                      {user.names} {user.lastnames}
                    </div>
                    <small>
                      {user._id}:{user.uuid}
                    </small>
                  </div>
                </div>
              </Td>
              <Td>{user.email}</Td>
              <Td>{user.issuer}</Td>
              <Td>{user.birthdate}</Td>
              <Td>
                <IconButton
                  title="Modify roles"
                  aria-label="Modify roles"
                  icon={<ReduceCapacityIcon />}
                  marginRight="8px"
                  onClick={() => openRoleModal(user)}
                />
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Associate roles</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <RolesSelector
              isChecked={isSelectedRole}
              onSelect={handleRoleSelect}
            />
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={doAssocRoles}>
              Save
            </Button>
            <Button variant="ghost" onClick={onClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}
